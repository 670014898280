import React, { useEffect, useState } from 'react';

export const getResponsive = () => {
  const [mobileView, setmobileView] = useState();
  const breakpoint = 768;
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < breakpoint) {
        setmobileView(true);
      } else {
        setmobileView(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return mobileView;
};
