import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Badge from '../../molecules/Badge/Badge';
import { colors } from '../../styles/atoms/colors';
import Link from '../../utils/Link';
import { getResponsive } from '../../utils/OptimizeResponsive';
import OptimizedImage from '../../utils/OptimizedImage';

const IntegrationContent = ({ activeFilter, integrations }) => {
  const mobileFlag = getResponsive();
  const availableIntegrations = useMemo(
    () =>
      integrations.map((integration, idx) => (
        <Col lg={6} md={6} key={`integraion_${idx}`} className="card-wrapper">
          <Link
            to={`/integrations/${integration?.slug}`}
            className="integration-card-wrapper"
          >
            <div className="integration-card" key={integration?.id}>
              <div className="logo-wrapper">
                {integration?.integrationImage?.image?.gatsbyImageData ? (
                  <OptimizedImage
                    image={
                      integration?.integrationImage?.image?.gatsbyImageData
                    }
                    alt={integration?.integrationName}
                    title={integration?.integrationImage?.image?.title}
                  />
                ) : (
                  <OptimizedImage
                    src={integration?.integrationImage?.image?.url}
                    alt={integration?.integrationName}
                    title={integration?.integrationImage?.image?.title}
                  />
                )}
              </div>
              <div className="details">
                <div className="body">
                  <h5 className="name">{integration?.title}</h5>
                  <p className="subtitle">{integration?.subtitle}</p>
                  <p className="description">{integration?.excerpt}</p>
                </div>
                <div className="footer">
                  <Badge
                    size="sm"
                    preserveCasing
                    badgeColor={colors.gray[50]}
                    textColor={colors.gray[800]}
                  >
                    {integration?.category?.name}
                  </Badge>
                  {mobileFlag && (
                    <Link
                      to={`/integrations/${integration?.slug}`}
                      className="link learn-more"
                    >
                      Learn more
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Link>
        </Col>
      )),
    [integrations, mobileFlag]
  );
  return (
    <>
      {integrations && integrations.length > 0 && (
        <div className="content">
          <p className="cat-title">{activeFilter}</p>
          <Row className="integrations-wrapper">{availableIntegrations}</Row>
        </div>
      )}
    </>
  );
};

export default IntegrationContent;
